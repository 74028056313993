/* .dialogTabs {
  margin-bottom: 20px;
  justify-content:flex-start;
  border-bottom: 1px solid;
  border-color:rgb(224 224 224);
  margin-top: 3px
} */

.dialogTabs [data-test*="ccfk-tabs-inner-container"] {
  max-width: 100%;
  overflow-x: scroll;
}