/* Container for the tabs */
.tabs {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  border-bottom: 0.0625rem solid var(--g-color-global-secondary-divider);
  font-family: "NokiaPureText", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: color 150ms cubic-bezier(0, 0, 0.2, 1);
  transition: color 150ms cubic-bezier(0, 0, 0.2, 1);
  white-space: normal;
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--ff-color-white);
}

/* Individual tab */
.tab {
  padding: var(--spacing-x-small) var(--spacing-large) var(--spacing-x-small) var(--spacing-large);
  border-radius: 0.25rem 0.25rem 0px 0px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0.25rem 0.25rem 0px 0px;
}

/* Active tab styling */

.tab.active {
  background-color: var(--g-color-global-primary-base);
  color: white;
}

.tab.active:hover {
  background-color: var(--g-color-tabs-active-hover);
  border-radius: 0.25rem 0.25rem 0px 0px;
}
/* Inactive tab styling */
.tab:not(.active) {
  background-color: white;
  color: black;
}

.tab:not(.active):hover {
  background-color: #f1f1f1;
}

/* Tab content */
.tab-content {
  padding: 20px 0;
}
