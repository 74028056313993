.radioConfigModalContent{
  display: block;
  margin: 5px 0;
}
.dynamicConfigContent{
    display: grid;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 0 25px;
}
/* .dynamicConfigContent > div{
  border: solid 1px red;
} */
/* .dynamicConfigContent div[class^="styles__Elevation-"]{
  border: solid 1px red;
} */