/* CSS Styles for Dashboard Page */
.dot-beadcrumb-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot-beadcrumb-nav-item {
  text-align: left;
  font: var(--ff-typography-label);
  color: var(--g-color-global-secondary-text);
  text-decoration: none;
}

.dsh-breadcrumb-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

/* Style for Succcess button */
.do-btn-success {
  background-color: var(--ff-color-green-600) !important;
  margin-left: 1rem !important;
  color: white !important;
}
.do-btn-success:hover:not(:disabled):not([data-ff-state="loading"]) {
  background-color: var(--ff-color-green-700) !important;
}
.do-btn-success:active:not(:disabled):not([data-ff-state="loading"]) {
  background-color: var(--ff-color-green-800) !important;
}
.do-btn-success:focus {
  box-shadow: 0 0 0 0.0625rem var(--ff-color-white),
    0 0 0 0.1875rem var(--g-color-global-primary-focus);
}
/* : #25B04D;
    --ff-color-green-700: #13902F;
    --ff-color-green-800: #077015; */

.do-btn-success *[data-selector="button--text"] {
  color: white !important;
}
.do-btn-success *[data-selector="button--icon"] * {
  fill: white !important;
}

/* Style for FilterToolbar */

.dsh-ft-wrapper {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding: 1rem 0;
  height: 40px;
}
.dsh-ft-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}
.dsh-ft-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
}
.dsh-ft-view .dsh-ft-view-tabs {
  justify-content: center;
  align-items: center;
}
.dsh-ft-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  height: 40px;
  gap: 1rem;
}

.dsh-ft-sort {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dsh-case-contents-pages {
  max-height: 70vh;
  min-height: 46vh;
  overflow: auto;
  padding: 20px 0;
}

/* My Cases Page */
.dsh-my-cases-content {
  display: flex;
  flex-direction: column;
  /* padding-top: 35px; */
}

/* .dsh-ogv-status-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
} */

.dsh-ogv-status-filter {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.dsh-ogv-grid-wrapper {
  display: flex;
  flex-direction: column;
}
.dsh-ogv-grid-wrapper .dsh-ogv-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns*/
  gap: 1rem;
  padding: 0 1rem;
}

/* List view - common data grid */
.dsh-olv-list-wrapper {
  display: flex;
  flex-direction: column;
}

/* Dashboard pagination */
.dsh-case-paginattion {
  position: relative;
}
.dsh-case-paginattion .status-bar {
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  /* background: #fff; */
  background: var(--g-color-app-banner-background);
}

.dsh-case-paginattion select.item-per-page {
  padding: 5px;
  border-radius: var(--radius-x-small);
  color: var(--g-color-global-secondary-content);
  border: 0;
  box-shadow: inset 0 0 0 0.0625rem var(--g-color-input-border);
}
.dsh-case-paginattion select.item-per-page:hover {
  box-shadow: inset 0 0 0 0.125rem var(--g-color-input-hover);
}
.dsh-case-paginattion select.item-per-page:active,
.dsh-case-paginattion select.item-per-page:focus-visible {
  box-shadow: 0 0 0 0.0625rem var(--ff-color-white),
    0 0 0 0.1875rem var(--g-color-global-primary-focus),
    inset 0 0 0 0.0625rem var(--g-color-input-border-selected);
}
.dsh-case-paginattion select.item-per-page:focus-within {
  box-shadow: 0 0 0 0.0625rem var(--ff-color-white),
    0 0 0 0.1875rem var(--g-color-global-primary-focus),
    inset 0 0 0 0.0625rem var(--g-color-input-border);
}
/*----------------------Card Styling ---------------------------*/

.dsh-flex {
  display: flex;
  align-items: center;
}
.gap05 {
  gap: 0.5rem;
}
.dsh-mt-1rem {
  margin-top: 1rem;
}
.dsh-oc-bold-text {
  font: var(--ff-typography-label);
  color: var(--g-color-global-secondary-text);
}

.dsh-offer-card {
  padding: 0.5rem 0;
  display: flex;
  gap: 2px;
  height: 100%;
}

.dsh-offer-card .dsh-oc-case-details {
  flex-basis: 90%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.dsh-oc-account {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--g-color-global-secondary-content);
  font: var(--ff-typography-body);
}
.dsh-offer-card .dsh-oc-actions {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}
.dot-dsh-card-avatar span {
  color: var(--g-color-toggle-group-content-on) !important;
}

.dsh-offer-card .dsh-oc-case-details .dsh-oc-row {
  width: 100%;
}

.dsh-oc-date {
  display: flex;
  gap: 5em;
  margin-top: 0.5rem;
  font: var(--ff-typography-body);
  line-height: 12px;
}

.dsh-oc-actions-more-buttons {
  display: flex;
  justify-content: right;
  width: 100%;
  gap: 0.5rem;
}
/*---------------------- Landing page---------------------------*/
.dsh-lp-container {
  text-align: center;
  margin: auto;
  width: 100vw;
  height: 100vh;
  position: relative;
  /* background-image: url(/static/media/dotBanner.7a8fca5….svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; */
}
.dsh-lp-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../assets/svg/dotBanner.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.dsh-lp-content {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 50%;
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

/*---------------------- Popup Loaader---------------------------*/
.dsh-moadal-loaader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
